  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/1-8-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li>Toiture</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Les désordres courants en toiture</h1>
<p>Les conséquences de désordres en couverture et toiture sont généralement des inondations consécutives à des infiltrations, des malfaçons lors de rénovation. </p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Toiture" />
</div>


<p>Les infiltrations interviennent généralement après des épisodes de pluies intenses et de tempête qui révèlent les imperfections laissées par exemple, par un artisan peu scrupuleux ou peu compétent. </p>

<p>Des épisodes de tempêtes, du vent peuvent engendrer de nombreux dégâts, sources de problèmes d’infiltrations en habitat.</p>

<p>La toiture terrasse est un ouvrage particulier et particulièrement pathogène si sa réalisation n’a pas suivi les règles et normes d’installation, notamment concernant l’étanchéité. </p>
<p>Les principales causes de désordres, à savoir des infiltrations, sont bien souvent des défauts d’étanchéité lors de sa mise en œuvre.</p>
<h2>Quand demander une expertise de votre toiture ? </h2>
<p>L’expertise de votre toiture devient nécessaire dans les configurations suivantes :</p>

<ul><li>des infiltrations constatées dans votre logement,</li>
<li>des dégâts consécutifs à une tempête, de fortes pluies ou des vents violents,</li>
<li>des malfaçons non décelées suite à des travaux de rénovation en couverture et toiture.</li></ul>

<p>Vous avez constaté des infiltrations suite à un épisode pluvieux ou une tempête ? </p>
<p>Votre plafond comporte des stigmates ou est éventré ? </p>
<p>Quelles sont les causes du sinistre ? </p>
<p>Sont-elles à relier aux travaux d’un couvreur peu scrupuleux ?</p>
<p>relèvent-elles d’un événement climatique exceptionnel ?</p>

<p>Les causes d’un tel sinistre sont souvent compliquées à déterminer et il peut être compliqué de déterminer, pour un non-sachant, la responsabilité d’une entreprise dans l’origine du sinistre ou bien d’impliquer les conséquences d’un événement climatique (tempêtes, averses…).</p>

<p>L’Expert technique du cabinet RV Expertises vous assiste pour déterminer les causes et origines de désordres ou d’un sinistre.</p>
<p>Il inspecte scrupuleusement votre toiture, de sa conception à sa réalisation, les matériaux engagés, les zones pathogènes appelées “points singuliers” afin de déterminer les défauts d'exécution et les non conformités.</p>

<p>L’Expert s’appuie sur les DTU en vigueur pour étayer son argumentaire qu’il retranscrit dans un rapport.</p>

<p>Suite à la réception de votre rapport d’expertise, vous avez alors toutes les clés en main être orienté au mieux pour réparer votre toiture, et si besoin est, dégager des responsabilités afin de défendre vos intérêts pour que les travaux de reprise soient pris, par exemple, par la décennale de l’entreprise ayant exécuté les travaux.</p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contactez le cabinet RV EXPERTISES</a>
    </div>
</div>


                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/toiture/' className='active'>Toiture</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details